<template>
<body>
    <div id="home" class="home">
        <div class="text-center">
            <img src="../assets/logo-escrita.png" class="logo img-fluid mt-5"  alt="Logo Night"> 
            <b-container class="box-content mx-auto text-center">
                <h1 class="text-apresentacao"> Conectando Você a Pessoas <br>e Experiências <strong
                 data-aos="fade-left" 
                 data-aos-duration="10000"
                 class="text-background">Incríveis</strong></h1>
            </b-container>
            <b-container class="d-flex justify-content-center mx-auto">
                <a href="https://apps.apple.com/br/app/nightapp/id1606457140" >
                <img class="img-fluid icons-bibliotecas mt-4" src="../assets/app_store.png" alt="Disponível na App Store">
                </a>
                <a href='https://play.google.com/store/apps/details?id=prod.nightapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img  class="img-fluid google-store" alt='Disponível no Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/pt_badge_web_generic.png'/>
                </a>
            </b-container>
        </div>
    </div>
 
    <div id="SeJoga">
        <div class="container d-flex justify-content-center mx-auto box-2">
            <b-row class="container d-flex justify-content-center mx-auto">
                <b-col class="text-center colum-1" data-aos="fade-up">
                    <h1>Se joga na <b>Night</b></h1>
                    <h2>A <b>NightApp</b> vem para resolver seu problema em buscar 
                        lugares para se divertir com seus amigos e facilita suas 
                        compras de ingressos e bebidas, mas não para por ai... 
                    </h2>
                    <div class=" text-center container d-flex justify-content-center">
                        <a href="https://www.instagram.com/nightbrasil/"><img class=" icon-insta" src="https://img.icons8.com/fluency/96/000000/instagram-new.png"/></a>
                        <a href="https://www.instagram.com/nightbrasil/"><h2 class="insta mt-4">@NIGHTBRASIL</h2></a>
                    </div>
                </b-col>
                <b-col class="text-center" data-aos="fade-up">
                    <img class="imagem-galera img-fluid" src="../assets/imagemDaGalera.png">
                </b-col>
                <h5 class="text-center">CONTINUE E SE SURPREENDA</h5>
                <img  class="scroll mb-5"  width="50px" src="../assets/scrolldown.png">
            </b-row>
        </div>
    </div>

    <div id="Festas">
        <div class="container box-3">
            <b-row class="row flex-column-reverse flex-md-row">
                <b-col>
                    <b-col class="text-center" 
                    data-aos="fade-up"
                    data-aos-duration="4000">
                        <img class="img-DJ img-fluid" src="../assets/Dj.png">
                    </b-col>
                </b-col>
                <b-col class="colum-1 mt-3">
                    <h1>AS MELHORES FESTAS VOCÊ ENCONTRA NA <strong class="text-background-2">Night</strong></h1>
                    <h2 class="mt-5"> As melhores experiências para curtir com seus amigos, você encontra na <b>NightApp</b>, se joga na <b> Night</b></h2>
                    <b-container class="d-flex justify-content-center mx-auto">
                        <a href="https://apps.apple.com/br/app/nightapp/id1606457140" >
                            <img class="img-fluid icons-bibliotecas-2 mt-4" src="../assets/app_store.png" alt="Disponível na App Store">
                        </a>

                        <a href='https://play.google.com/store/apps/details?id=prod.nightapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                            <img  class="img-fluid icons-bibliotecas-google-2 mt-4" alt='Disponível no Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/pt_badge_web_generic.png'/>
                        </a>                    
                    </b-container>
                </b-col>
            </b-row>
            <b-row>
                 <b-col class="colum-1 mt-3 "  >
                    <h1>QUER DAR UMA ESPIADA EM QUEM VAI NA MESMA <br><strong class="text-background-2">Night </strong> QUE VOCÊ?</h1>
                    <h4 class="mt-5"> 
                        Na <b>NightApp</b> você conseque saber quem são as pessoas que estarão na mesma 
                        <b>Festa</b>, mesmo <b> Rolê</b> ou mesmo <b> Show</b> que você,
                        acesse, a <b>NightApp</b> e descubra as 
                        <b> novidades</b></h4>
                    <b-container class="d-flex justify-content-center mx-auto">
                        <a href="https://apps.apple.com/br/app/nightapp/id1606457140" >
                        <img class="img-fluid icons-bibliotecas-2 mt-4" src="../assets/app_store.png" alt="Icon App Store">
                        </a>
                        <a href='https://play.google.com/store/apps/details?id=prod.nightapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                            <img  class="img-fluid icons-bibliotecas-google-2 mt-4" alt='Disponível no Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/pt_badge_web_generic.png'/>
                        </a> 
                    </b-container>
                </b-col>
                <b-col>
                    <b-col class="text-center" 
                    data-aos="fade-up"
                    data-aos-duration="5000">
                        <img class="img-DJ img-fluid" src="../assets/loirafestando.png">
                    </b-col>
                </b-col>
            </b-row>
        </div>
    </div>
    
    <div id="Sobre">
        <div class="container d-flex justify-content-center mx-auto box-5">
            <b-row class="container d-flex justify-content-center mx-auto mb-5">
                <div class="text-center colum-1" data-aos="fade-up">
                    <img class="img-fluid"  alt="Logo Night" src="../assets/big-logo.png">
                    <h2>Entregamos para Você 
                    </h2>
                </div>
                <b-col class="text-center mt-5" data-aos="fade-up">
                    <h2 class="box-title">SEGURANÇA</h2>
                    <h4> Você curte suas festas e entregamos a segurança 
                        nos dados e nos pagamentos, tanto para o evento quanto para o cliente
                    </h4>
                </b-col>
                <b-col class="text-center mt-5" data-aos="fade-up">
                    <h2 class="box-title">COMODIDADE</h2>
                    <h4>Os eventos realizados pela <b>NightApp</b> são super fáceis de serem adquiridos, tanto os ingressos quanto as bebidas,
                         <b> SEM FILAS</b>
                    </h4>
                </b-col>
                <b-col class="text-center mt-5" data-aos="fade-up">
                    <h2 class="box-title">EXPERIÊNCIAS</h2>
                    <h4>Sua Night nunca mais será a mesma, e sabe o motivo?<br>
                    <b>Experiência...</b>
                    Nossa meta é fazer você curtir do jeito 
                    <b> Night</b></h4>
                </b-col>
            </b-row>
        </div>
    </div>

    <div id="Final">
        <b-row class="container d-flex justify-content-center mx-auto box-6"> 
            <b-col>
                 <img src="../assets/logo-escrita.png" class="logo-footer img-fluid  mx-auto mt-5"  alt="Logo Night"> 
            </b-col>
            <b-col class="text-center colum-1" data-aos="fade-up">
                <h1>
                    Entre em contato com a <b>NightApp</b>
                </h1>
                <b-col>
                    <a href="#"><img  class="img-fluid icones" src="../assets/Twitter_white.png"></a>
                    <a href="#"><img  class="img-fluid icones" src="../assets/Face_white.png"></a>
                    <a href="#"><img class="img-fluid icones" src="../assets/Youtube_white.png"></a>
                    <a href="https://www.instagram.com/nightbrasil/"><img class="img-fluid icones" src="../assets/instagram_white.png"></a>
                </b-col>
                <b-col>
                    <h2  class="box-title mt-5 p-2">  <a  href="https://suporte.nightapp.me/"> suporte.nightapp.me</a></h2>
                </b-col>
                <b-col class="container d-flex justify-content-center mt-5 mb-5">
                    <b-link  class="btn btn-outline-light mx-3 text-decoration-none" href="https://nightpromoter.nightapp.me/">Trabalhe conosco</b-link>
                    <b-link  class="btn btn-outline-light mx-3 text-decoration-none" href="https://privacidade.nightapp.me/">Politícas de privacidade</b-link>

                </b-col>
            </b-col>
        </b-row>
    </div>

   <footer  class="bg-dark text-center text-white">
        <div class="text-center p-3">
        © 2022 Copyright: <b>Nightapp.me</b>
        </div>
   </footer>
</body>
</template>
<script>
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

export default{
      
    
}
</script>
<style>
@import '../styleglobal.css';
</style>